/**
 * @description Layout variables for the main content wrapper
 */
export const mainContentWrapperProps = {
  default: {
    maxW: '7xl'
  },
  extended: {
    //TODO: Add extended main content layout
  }
};
